import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_nestsquare/layout/core'
import {
  MixedWidget2,
  PostsOverview,
  MixedWidget10,
  MixedWidget11,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget10,
  StatisticsWidget5,
  ListsWidget1,
} from '../../../_nestsquare/partials/widgets'
import { ListingListView } from "../../modules/listings/partials/ListingListView";

const ListingSummaryPage = () => (
  <>

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget1 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <PostsOverview
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='200px'
            strokeColor='#fff'
        />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
        />
        <MixedWidget11
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      {/* begin::Col */}
      <div className='col-xl-4'>
        <ListsWidget1 className='card-xl-stretch mb-xl-8' />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xl-8'>
        <ListingListView className='card-xl-stretch mb-5 mb-xl-8' title='Your Recent Listings' />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget6 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const ListingsSummaryWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <ListingSummaryPage />
    </>
  )
}

export {ListingsSummaryWrapper}
