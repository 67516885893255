/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <div className="fw-bold py-5">
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/general/gen025.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
      />
      <AsideMenuItem to="/listing-summary" icon="/media/icons/duotune/general/gen019.svg" title="Post a Listing" />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Account</span>
        </div>
      </div>

      <AsideMenuItem to="/listings/overview" title="Your Listings" icon="/media/icons/duotune/general/gen019.svg" />

      <AsideMenuItem to="/crafted/widgets/mixed" title="Listing Reports" icon="/media/icons/duotune/arrows/arr070.svg" />

      <AsideMenuItem to="/profile/documents" title="Saved Listings" icon="/media/icons/duotune/communication/com012.svg" />

      <AsideMenuItem to="/apps/chat/private-chat" title="Messages"
                     icon="/media/icons/duotune/communication/com012.svg" />

      <AsideMenuItemWithSub to="#" title="Account & Profile" icon="/media/icons/duotune/ecommerce/ecm007.svg">
        <AsideMenuItem to="/account/overview" title="Your Profile" hasBullet={true} />
        <AsideMenuItem to="/profile/connections" title="Neighbourhoods" hasBullet={true} />
        <AsideMenuItem to="/apps/user-management/users" title="My Team" hasBullet={true} />
        <AsideMenuItem to="/account/settings" title="Settings" hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem to="/crafted/pages/wizards" title="Subscription"
                     icon="/media/icons/duotune/ecommerce/ecm007.svg" />

      <AsideMenuItemWithSub to="#" title="General Tools" icon="/media/icons/duotune/ecommerce/ecm007.svg">
        <AsideMenuItem to="/profile/overview" title="Property Alerts" hasBullet={true} />
        <AsideMenuItem to="/listing-summary" title="Property Requests" hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-2"></div>
        </div>
      </div>

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Settings</span>
        </div>
      </div>


      <AsideMenuItem to="#" icon="/media/icons/duotune/general/gen051.svg" title="Settings" />
      <AsideMenuItem to="#" icon="/media/icons/duotune/general/gen051.svg" title="Log Out" />
    </div>
  );
}
