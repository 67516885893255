import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_nestsquare/layout/core'
import {
  MixedWidget2,
  PostsOverview,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget10,
  StatisticsWidget5,
  ListsWidget1, StatisticsWidget1, StatisticsWidget2,
} from '../../../_nestsquare/partials/widgets'
import React from "react";
import { TiledWidget } from "./components/TiledWidget";
import { ListingListView } from "../../modules/listings/partials/ListingListView";

const DashboardPage = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <TiledWidget
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/general/gen008.svg'
          color='dark'
          iconColor='gray-100'
          title='Manage Listings'
          description='Add, update or remove your listings'
          titleColor='gray-100'
          descriptionColor='gray-100'
          toLink='/listings/overview'
        />
      </div>

      <div className='col-xl-4'>
        <TiledWidget
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/finance/fin010.svg'
          color='primary'
          iconColor='white'
          title='Subscription'
          description='Manage subscription and credit balance'
          titleColor='white'
          descriptionColor='white'
         toLink='/subscriptions'/>
      </div>

      <div className='col-xl-4'>
        <TiledWidget
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='/media/icons/duotune/arrows/arr070.svg'
          color='dark'
          iconColor='gray-100'
          title='Performance'
          description='See how your listings are performing'
          titleColor='gray-100'
          descriptionColor='gray-100'
         toLink='/reports'/>
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/arrows/arr070.svg'
            color='danger'
            iconColor='white'
            title='Messages'
            description='Read or respond to messages you have received.'
            titleColor='white'
            descriptionColor='white'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar='/media/svg/avatars/014-girl-7.svg'
            title='Property Requests'
            description='Manage your property requests or view list of active requests'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget1
            className='card-xl-stretch mb-5 mb-xl-8'
            image='abstract-1.svg'
            title='Customer Support'
            time='Chat or Call +234 906 000 6374'
            description='Your account officer is Oluseun and she`s on standby to provide assistance'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      {/* begin::Col */}
      <div className='col-xl-8'>
        <ListingListView className='card-xl-stretch mb-5 mb-xl-8' title='Your Recent Listings' />
      </div>
      {/* end::Col */}

      <div className='col-xxl-4'>
        <ListsWidget1 className='card-xxl-stretch' />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
