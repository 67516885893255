
import {toAbsoluteUrl} from '../../../../_nestsquare/helpers'

export function SSO() {
  return (
    <>
      {/* begin::Col */}
      <div className='col-md-6'>
        {/* begin::Google link */}
        <a
          href='#'
          className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-15px me-3'
          />
          Continue with Google
        </a>
        {/* end::Google link */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6'>
        {/* begin::Google link */}
        <a
          href='#'
          className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
            className='theme-light-show h-15px me-3'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='theme-dark-show h-15px me-3'
          />
          Continue with Apple
        </a>
        {/* end::Google link */}
      </div>
      {/* end::Col */}
    </>
  )
}
