/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
}

const ListsWidget1: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Quick Actions</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Commonly used actions</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-success border-gray-400 border'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs027.svg'
                className='svg-icon-2x svg-icon-success'
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              Post a Listing
            </a>
            <span className='text-muted fw-semibold'>Post any type of listing</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-warning border-gray-400 border'>
              <KTSVG
                path='/media/icons/duotune/art/art005.svg'
                className='svg-icon-2x svg-icon-warning'
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              Update Profile
            </a>
            <span className='text-muted fw-semibold'>Bring your profile up-to-date</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-primary border-gray-400 border'>
              <KTSVG
                path='/media/icons/duotune/communication/com012.svg'
                className='svg-icon-2x svg-icon-primary'
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              Manage Subscription
            </a>
            <span className='text-muted fw-semibold'>Renew or extend your subscription</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-danger border-gray-400 border'>
              <KTSVG
                path='/media/icons/duotune/coding/cod008.svg'
                className='svg-icon-2x svg-icon-danger'
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              Property Request
            </a>
            <span className='text-muted fw-semibold'>Post property request</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-info border-gray-400 border'>
              <KTSVG
                path='/media/icons/duotune/general/gen049.svg'
                className='svg-icon-2x svg-icon-info'
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              Coverage Areas
            </a>
            <span className='text-muted fw-semibold'>Manage your neighbourhoods</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget1}
