/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_nestsquare/helpers";

type Props = {
  className: string
  title: string
}

const ListingListView: React.FC<Props> = ({ className, title }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{title}</span>
          <span className="text-muted mt-1 fw-semibold fs-7">More than 400 new products</span>
        </h3>
        <div className="card-toolbar">
          <ul className="nav">
            <li className="nav-item">
              <a
                className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1"
                data-bs-toggle="tab"
                href="#kt_table_widget_5_tab_1"
              >
                Active
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"
                data-bs-toggle="tab"
                href="#kt_table_widget_5_tab_2"
              >
                Inactive
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"
                data-bs-toggle="tab"
                href="#kt_table_widget_5_tab_2"
              >
                In Progress
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4"
                data-bs-toggle="tab"
                href="#kt_table_widget_5_tab_3"
              >
                Drafts
              </a>
            </li>

          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        <div className="tab-content">
          {/* begin::Tap pane */}
          <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4">
                {/* begin::Table head */}
                <thead>
                <tr className="border-0">
                  <th className="p-0 w-50px"></th>
                  <th className="p-0 min-w-150px"></th>
                  <th className="p-0 min-w-140px"></th>
                  <th className="p-0 min-w-110px"></th>
                  <th className="p-0 min-w-50px"></th>
                </tr>
                </thead>
                {/* end::Table head */}

                {/* begin::Table body */}
                <tbody>

                {(() => {
                  let tr = [];
                  for (let i = 1; i <= 5; i++) {
                    tr.push(
                      <tr>
                        <td>
                          <div className="symbol symbol-100px symbol-2by3 me-2">
                        <span className="symbol-label">
                          <img
                            src={toAbsoluteUrl("/media/svg/brand-logos/plurk.svg")}
                            className="h-50 align-self-center"
                            alt=""
                          />
                        </span>
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-primary fw-bold text-hover-primary mb-1 fs-5">
                            4 Bedroom Flat For Sale
                          </a>
                          <span className="text-muted fw-semibold d-block">Ilupeju Area, off Sango Eleyele Road</span>
                          <span className="text-muted fw-semibold d-block fs-7">Ref: XFT4F6</span>

                          <span className="text-muted d-block fs-7">Added: 4 days ago</span>
                        </td>
                        <td className="text-end text-muted fw-bold">₦6,500,000</td>
                        <td className="text-end">
                          <span className="badge badge-light-success">For Sale</span>
                        </td>
                        <td className="text-end">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr064.svg"
                              className="svg-icon-2"
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  }
                  return tr;
                })()}

                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className="tab-pane fade" id="kt_table_widget_5_tab_2">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4">
                {/* begin::Table head */}
                <thead>
                <tr className="border-0">
                  <th className="p-0 w-50px"></th>
                  <th className="p-0 min-w-150px"></th>
                  <th className="p-0 min-w-140px"></th>
                  <th className="p-0 min-w-110px"></th>
                  <th className="p-0 min-w-50px"></th>
                </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {(() => {
                  let tr = [];
                  for (let i = 1; i <= 3; i++) {
                    tr.push(
                      <tr>
                        <td>
                          <div className="symbol symbol-100px symbol-2by3 me-2">
                        <span className="symbol-label">
                          <img
                            src={toAbsoluteUrl("/media/svg/brand-logos/plurk.svg")}
                            className="h-50 align-self-center"
                            alt=""
                          />
                        </span>
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-primary fw-bold text-hover-primary mb-1 fs-5">
                            4 Bedroom Flat For Sale
                          </a>
                          <span className="text-muted fw-semibold d-block">Ilupeju Area, off Sango Eleyele Road</span>
                          <span
                            className="text-muted d-block fs-7">Added: 4 days ago</span>
                        </td>
                        <td className="text-end text-muted fw-bold">₦6,500,000</td>
                        <td className="text-end">
                          <span className="badge badge-light-success">For Sale</span>
                        </td>
                        <td className="text-end">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr064.svg"
                              className="svg-icon-2"
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  }
                  return tr;
                })()}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className="tab-pane fade" id="kt_table_widget_5_tab_3">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-bordered table-row-gray-300 align-middle gs-0 gy-4">
                {/* begin::Table head */}
                <thead>
                <tr className="border-0">
                  <th className="p-0 w-50px"></th>
                  <th className="p-0 min-w-150px"></th>
                  <th className="p-0 min-w-140px"></th>
                  <th className="p-0 min-w-110px"></th>
                  <th className="p-0 min-w-50px"></th>
                </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {(() => {
                  let tr = [];
                  for (let i = 1; i <= 4; i++) {
                    tr.push(
                      <tr>
                        <td>
                          <div className="symbol symbol-100px symbol-2by3 me-2">
                        <span className="symbol-label">
                          <img
                            src={toAbsoluteUrl("/media/svg/brand-logos/plurk.svg")}
                            className="h-50 align-self-center"
                            alt=""
                          />
                        </span>
                          </div>
                        </td>
                        <td>
                          <a href="#" className="text-primary fw-bold text-hover-primary mb-1 fs-5">
                            4 Bedroom Flat For Sale
                          </a>
                          <span className="text-muted fw-semibold d-block">Ilupeju Area, off Sango Eleyele Road</span>
                          <span
                            className="text-muted d-block fs-7">Added: 4 days ago</span>
                        </td>
                        <td className="text-end text-muted fw-bold">₦6,500,000</td>
                        <td className="text-end">
                          <span className="badge badge-light-success">For Sale</span>
                        </td>
                        <td className="text-end">
                          <a
                            href="#"
                            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr064.svg"
                              className="svg-icon-2"
                            />
                          </a>
                        </td>
                      </tr>
                    );
                  }
                  return tr;
                })()}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ListingListView };
